<template>
  <div>
    <div class="navbarandsidebar">
      <div v-if="showSidebar === true" class="sidebar">
        <div class="icon">
          <img @click="toggleSidebar()" src="../assets/close.svg" />
        </div>
        <div class="sidebar-menu">
          <div class="menu">
            <a @click="toggleSidebar()" href="#skills" class="menu-name"
              >Skills</a
            >
            <a href="#workexperience" class="menu-name" @click="toggleSidebar()"
              >Work Experiences</a
            >
            <a href="#projects" class="menu-name" @click="toggleSidebar()"
              >Projects</a
            >
            <a href="#contact" class="menu-name" @click="toggleSidebar()"
              >Contact</a
            >
          </div>
        </div>
      </div>
      <div class="navbar-div">
        <div class="nav-links-div">
          <a href="#home" class="head-link">
            <p class="logo">🇳🇵Aashirwad Shrestha</p>
          </a>
          <div class="mobile-view">
            <img @click="toggleSidebar()" src="../assets/menu.svg" />
          </div>
          <div class="nav-menus">
            <a href="#skills" class="link-names">Skills</a>
            <a href="#workexperience" class="link-names">Work Experiences</a>
            <a href="#projects" class="link-names">Projects</a>
            <a href="#contact" class="link-names">Contact</a>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div id="home" class="content">
          <LandingPage />
        </div>
        <div id="skills" class="content">
          <Skills />
        </div>
        <div id="workexperience" class="content">
          <WorkExperience />
        </div>
        <div id="projects" class="content">
          <PersonalProjects />
        </div>
        <div id="contact" class="content">
          <Contact />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LandingPage from "./LandingPage.vue";
import Skills from "./Skills.vue";
import WorkExperience from "./WorkExperience.vue";
import PersonalProjects from "./PersonalProjects.vue";
import Contact from "./Contact.vue";

export default {
  name: "Navbar",
  components: {
    LandingPage,
    Skills,
    WorkExperience,
    PersonalProjects,
    Contact,
  },
  data: function() {
    return {
      showSidebar: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

.navbarandsidebar {
  position: sticky;
  top: 0;
  z-index: 10;
}

.navbar-div {
  width: 100%;
  height: 50px;
  background-color: #171c28;
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links-div {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  max-width: 1100px;
  width: 100%;
  z-index: 1;
}

.link-names {
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: white;
  cursor: pointer;
  height: 100%;
  padding: 0 1rem;
  margin-bottom: 0;
  text-decoration: none;
}

.link-names:hover {
  border-bottom: 3px solid white;
  transition: 0.2s ease-in-out;
  color: white;
}

.head-link {
  text-decoration: none;
}

.logo {
  font-size: 18px;
  color: white;
  font-family: "Comfortaa", sans-serif;
  font-weight: bold;
  display: flex;
  justify-self: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
}

.mobile-view {
  display: none;
}

.nav-menus {
  display: flex;
  text-align: center;
  align-items: center;
}

/* .wrapper,
.content {
  height: 93.75vh;
} */

.wrapper {
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.content {
  scroll-snap-align: start;
}

@media screen and (max-width: 768px) {
  .mobile-view {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
  }

  .nav-menus {
    display: none;
  }
}

.sidebar {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #171c28;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.8s ease-in-out;
}

.icon {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  /* font-size: 2rem; */
  cursor: pointer;
  outline: none;
}

.sidebar-menu {
  background: #171c28;
}

.menu {
  display: grid;
  grid-template: 1fr;
  grid-template-rows: repeat(4, 100px);
  text-align: center;
}

.menu-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  color: white;
}

.menu-name:hover {
  color: green;
  transition: 0.2s ease-in-out;
}
</style>
