<template>
  <div class="work-experience">
    <div class="wrapper">
      <div class="heading">
        Work Experiences
      </div>
      <!-- <hr class="w-100" /> -->
      <b-row class="content-row">
        <b-col
          class="content-col"
          :lg="colBreakpoint"
          md="6"
          sm="12"
          v-for="portfolio in portfolios"
          :key="portfolio.title"
        >
          <div class="card-wrapper">
            <div class="img-section">
              <img :src="portfolio.img" />
            </div>
            <div class="title">{{ portfolio.title }}</div>
            <div class="date">{{ portfolio.date }}</div>
            <div class="description" v-html="portfolio.description" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkExperience",
  data: function() {
    return {
      webbfontaine: require("../assets/webbfontaine.png"),
      mercantile: require("../assets/mercantile.jpg"),
      portfolios: [
        {
          title: "IT Support",
          description: `✅ Created and configured dashboards using: ELK Stack and
                Grafana.
                <br />
                ✅ Managed wireless and wired networks
                <br />
                ✅ Monitored IT infrastructures: Network, Servers and
                Applications Status
                <br />
                ✅ Deployed hardwares and provided technical support`,
          date: "February 2021 – September 2021",
          img: require("../assets/webbfontaine.png"),
        },
        {
          title: "NOC Support",
          description: `✅ Configured networking devices: Mikrotik, Dlink, TPlink, Raisecom, CISCO and provided client side support
                <br />
                ✅ Designed and developed Web Application for Inventory Management System of Company’s Network Department in Django
                <br />`,
          date: "August 2019 – January 2020",
          img: require("../assets/mercantile.jpg"),
        },
        {
          title: "Founder",
          description: `✅ This company was started in order to provide people with freedom to customize their own piece of clothing.
          <br />
          ✅ Provided employment oppertunities to people during the global pandemic.<br />
          ✅ Provided face mask for on site workers during the pandemic`,
          date: "October 2020 – Present",
          img: require("../assets/embroiderynepal.png"),
        },
      ],
    };
  },
  computed: {
    colBreakpoint() {
      if (this.portfolios.length < 6) {
        let breakpoint = Math.floor(12 / this.portfolios.length);

        breakpoint % 2 !== 0 ? breakpoint++ : null;

        return breakpoint < 4 ? 4 : breakpoint;
      } else {
        return 4;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

.work-experience {
  color: wheat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 3rem;
  padding-top: 1px; */
}

.wrapper {
  padding: 5rem;
}

.cards {
  width: 100%;
  min-height: 30rem;
}

.content-row {
  /* --bs-gutter-x: 0; */
  padding-top: 2rem;
  text-align: center;
  /* overflow-y: scroll;
  max-height: 30rem; */
}

.content-col {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.img-section {
  overflow: hidden;
}

.img-section > img {
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: fill;
  max-height: 200px;
  object-position: center;
  transition: 200ms transform ease-in-out;
}

.card-wrapper:hover > .img-section > img {
  transform: scale(1.025);
}

.card-wrapper {
  background: #171c28;
  /* padding: 1rem; */
  border-radius: 0.5rem;
  box-shadow: 0 3px 5px 0 rgba(245, 222, 179, 0.5);
  max-width: 400px;
  overflow: hidden;
}

.title {
  font-family: "Comfortaa", sans-serif;
  font-size: 1.5rem;
  padding: 5px;
  text-align: center;
}

.date {
  font-family: "Monteserrat", sans-serif;
  font-size: 14px;
}

.description {
  padding: 1rem 1rem;
  text-align: start;
}

/* .content-col {
  margin-bottom: 10px;
} */

/* .company-card {
  min-height: 28rem;
}

.card-title {
  font-family: "Comfortaa", sans-serif;
  font-size: 22px;
  font-weight: bold;
} */

.heading {
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  font-size: 42px;
}

@media screen and (max-width: 1140px) {
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 36px;
  }

  .content-col {
    margin-bottom: 20px;
  }

  .wrapper {
    padding: 3rem;
  }
}

@media screen and (max-width: 564px) {
  .heading {
    font-size: 28px;
  }

  .wrapper {
    padding: 2rem;
    padding-top: 5rem;
  }

  /* .content-row {
    max-height: 35rem;
    margin-top: 1rem;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .content-col {
    min-height: 35rem;
    scroll-snap-align: start;
  } */
}
</style>
