<template>
  <div class="personal-projects">
    <div class="wrapper">
      <div class="heading">
        Projects
      </div>
      <b-row class="row-content">
        <b-col
          md="6"
          lg="4"
          class="col-content"
          v-for="project in projects"
          :key="project.projectTitle"
        >
          <div class="card-wrapper">
            <div class="img-section">
              <img :src="project.projectLogo" />
            </div>
            <div class="title">{{ project.projectTitle }}</div>
            <div class="description" v-html="project.description" />
            <div class="button-div">
              <b-button :href="project.websiteLink" target="_blank"
                >View Work</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalProject",
  data: function() {
    return {
      projects: [
        {
          projectLogo: require("../assets/uberlogo.jpg"),
          projectTitle: "Uber Clone",
          description: `This Uber Clone is build using Next.js, TailwindCSS Styled
              Component, Firebase and Google Authentication. Mapbox API is used
              for rendering map, geo-coding and duration between the two chosen
              locations. The app calculates the tentative cost for
              the ride according to the vehicle.`,
          websiteLink: "https://awesomeuberclone.netlify.app",
        },
        {
          projectLogo: require("../assets/amazonlogo.png"),
          projectTitle: "Amazon Clone",
          description: `This Amazon Clone is build using simple Vanilla JS, TailwindCSS, 
              Firebase. The original amazon has been taken as a reference and few design
              changes has been made. The products shown are fetched from firebase and items 
              can be added to the cart updating the total price accordingly.`,
          websiteLink: "https://awesomeamazonclone.netlify.app",
        },
        {
          projectLogo: require("../assets/imageconverter.png"),
          projectTitle: "Image Converter & Compressor",
          description: `This Image Converter and Compressor is build using Vue JS and TailwindCSS.
          The REST API was developed by my friend Satshree Shrestha. The idea to build this app hit us
          when we had tough time uploading images in the form.`,
          websiteLink: "https://image-converter-compressor.netlify.app",
        },
      ],
    };
  },
};
</script>

<style scoped>
.personal-projects {
  color: wheat;
  min-height: 93.75vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  padding: 5rem;
}

.col-content {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.heading {
  font-family: "Comfortaa", sans-serif;
  font-size: 42px;
  margin-bottom: 2rem;
  text-align: center;
}

.img-section {
  overflow: hidden;
}

.img-section > img {
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  max-height: 200px;
  object-position: center;
  transition: 200ms transform ease-in-out;
}

.card-wrapper:hover > .img-section > img {
  transform: scale(1.025);
}

.card-wrapper {
  background: #171c28;
  /* padding: 1rem; */
  border-radius: 0.5rem;
  box-shadow: 0 3px 5px 0 rgba(245, 222, 179, 0.5);
  max-width: 400px;
  overflow: hidden;
}

.title {
  font-family: "Comfortaa", sans-serif;
  font-size: 1.5rem;
  padding: 1rem;
  text-align: center;
}

.description {
  padding: 0 1rem;
  text-align: center;
}

.button-div {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 3rem;
  }
}

@media screen and (max-width: 564px) {
  .wrapper {
    padding: 2rem;
    padding-top: 5rem;
  }
}
</style>
