<template>
  <div id="app">
    <Navbar />
  </div>
</template>

<script>
// import LandingPage from "./components/LandingPage.vue";
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
};
</script>

<style>
* {
  background-color: #171c28;
  scroll-behavior: smooth;
}
</style>
