<template>
  <div class="skills">
    <b-row class="content-row w-100">
      <b-col lg="6" order-lg="2" order-sm="1">
        <div class="heading-div">
          What I do...
        </div>
        <br />
        <div class="whatido-div">
          WEB APPLICATION DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK
        </div>
        <br />
        <div class="icons-div">
          <font-awesome-icon :icon="['fab', 'html5']" class="brand-icons" />
          <font-awesome-icon :icon="['fab', 'css3-alt']" class="brand-icons" />
          <font-awesome-icon :icon="['fab', 'js-square']" class="brand-icons" />
          <font-awesome-icon :icon="['fab', 'react']" class="brand-icons" />
          <font-awesome-icon :icon="['fab', 'vuejs']" class="brand-icons" />
          <font-awesome-icon :icon="['fab', 'npm']" class="brand-icons" />
          <font-awesome-icon :icon="['fab', 'figma']" class="brand-icons" />
          <font-awesome-icon :icon="['fab', 'bootstrap']" class="brand-icons" />
        </div>
        <br />
        <br />
        <div class="myskills-div">
          <span class="spans">
            ⚡ Develop highly interactive Front end / User Interfaces for your
            web and mobile applications
          </span>
          <span class="spans">
            ⚡ Progressive Web Applications ( PWA )
          </span>
          <span>
            ⚡ Integration of Rest API's
          </span>
        </div>
      </b-col>
      <br />
      <b-col class="lottie-column" lg="6" order-lg="1" order-sm="2">
        <lottie-player
          src="https://assets6.lottiefiles.com/packages/lf20_bp5lntrf.json"
          background="transparent"
          speed="1"
          class="lottie"
          loop
          autoplay
        ></lottie-player>
      </b-col>
    </b-row>
    <!-- <img :src="mygif" /> -->
  </div>
</template>

<script>
export default {
  name: "Skills",
  data: function() {
    return {
      mygif: require("../assets/mygif.gif"),
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

.skills {
  color: wheat;
  min-height: 93.75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-row {
  /* transform: translateY(300px); */
  --bs-gutter-x: 0;
  padding: 5rem;
}

.lottie {
  width: 500px;
}

.heading-div {
  font-family: "Comfortaa", sans-serif;
  font-size: 42px;
  font: bolder;
}

.whatido-div {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}

.icons-div {
  font-size: 50px;
  color: lightgray;
  width: 85%;
  display: flex;
  justify-content: space-between;
}

.brand-icons {
  transition: 0.3s ease-in-out;
}

.brand-icons:hover {
  color: wheat;
}

.myskills-div {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.spans {
  margin-bottom: 10px;
}

@media screen and (max-width: 1140px) {
  .content-row {
    padding: 2rem;
  }
}

@media screen and (max-width: 992px) {
  .content-row {
    padding: 5rem;
  }

  .lottie-column {
    display: flex;
    justify-content: center;
    padding-top: 25px;
  }
}

@media screen and (max-width: 768px) {
  .content-row {
    padding: 5rem;
  }

  .lottie {
    width: 375px;
  }

  .icons-div {
    font-size: 40px;
  }

  .skills {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}

@media screen and (max-width: 564px) {
  .lottie {
    width: 250px;
  }

  .content-row {
    padding: 2rem;
    padding-top: 5rem;
  }

  .heading-div {
    font-size: 28px;
  }

  .whatido-div {
    font-size: 16px;
  }

  .icons-div {
    font-size: 30px;
    width: 100%;
  }

  .myskills-div {
    font-size: 16px;
  }

  .skills {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .lottie-column {
    padding-top: 60px;
  }
}
</style>
