<template>
  <div class="contact">
    <b-row class="row-content  w-100">
      <b-col lg="6">
        <div class="heading">
          Let's Connect and Get To Know Each Other
        </div>
        <br />
        <br />
        <div class="phone-number">
          +1(630)-754-9583
          <font-awesome-icon :icon="['fas', 'sms']" class="brand-icons" />
        </div>
        <br />
        <div class="email">
          aashirwad43@gmail.com
          <font-awesome-icon :icon="['fas', 'envelope']" class="brand-icons" />
        </div>
        <br />
        <div class="location">
          Chicago, Illinois, USA
          <font-awesome-icon
            :icon="['fas', 'location-arrow']"
            class="brand-icons"
          />
        </div>
        <br />
        <br />
        <div class="socials">
          <a href="https://github.com/aashirwad43" target="_blank"
            ><img :src="github" class="socials-icons"
          /></a>
          <a
            href="https://www.linkedin.com/in/aashirwad-shrestha-033a69195/"
            target="_blank"
            ><img :src="linkedin" class="socials-icons"
          /></a>
          <a href="https://www.facebook.com/aashirwad.shrestha" target="_blank"
            ><img :src="facebook" class="socials-icons"
          /></a>
          <a href="https://www.instagram.com/aashirstha/" target="_blank"
            ><img :src="instagram" class="socials-icons"
          /></a>
        </div>
      </b-col>
      <b-col lg="6" class="lottie-column">
        <lottie-player
          src="https://assets10.lottiefiles.com/packages/lf20_bt7iqzns.json"
          background="transparent"
          speed="1"
          class="lottie"
          loop
          autoplay
        ></lottie-player>
      </b-col>
    </b-row>
    <div class="footer">
      Made by Aashirwad Shrestha
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data: function() {
    return {
      facebook: require("../assets/facebook.png"),
      instagram: require("../assets/instagram.png"),
      linkedin: require("../assets/linkedin.png"),
      github: require("../assets/github-sign.png"),
    };
  },
};
</script>

<style scoped>
.contact {
  min-height: 93.75vh;
  color: wheat;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row-content {
  --bs-gutter-x: 0;
  padding: 5rem;
}

.heading {
  font-family: "Comfortaa", sans-serif;
  font-size: 42px;
  font: bolder;
}

.phone-number {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font: bolder;
}

.email {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font: bolder;
}

.location {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font: bolder;
}

.socials {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.socials-icons {
  transition: 0.5s;
  width: 40px;
  cursor: pointer;
}

.socials-icons:hover {
  transform: translateY(-6px);
}

.lottie {
  width: 500px;
}

.brand-icons {
  transition: 0.3s ease-in-out;
  color: lightgray;
  margin-left: 10px;
}

.brand-icons:hover {
  color: wheat;
}

.lottie-column {
  display: flex;
  justify-content: center;
}

.footer {
  font-family: "Comfortaa", sans-serif;
  font-size: 18px;
  font: bolder;
  padding-top: 25px;
}

@media screen and (max-width: 1140px) {
  .row-content {
    padding: 2rem;
  }
}

@media screen and (max-width: 992px) {
  .row-content {
    padding: 5rem;
  }

  .lottie-column {
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
}

@media screen and (max-width: 768px) {
  .lottie {
    width: 375px;
  }

  .heading {
    font-size: 36px;
  }

  .phone-number {
    font-size: 32px;
  }

  .email {
    font-size: 28px;
  }

  .location {
    font-size: 28px;
  }
}

@media screen and (max-width: 564px) {
  .lottie {
    width: 250px;
  }

  .row-content {
    padding: 2rem;
    padding-top: 3.5rem;
  }

  .heading {
    font-size: 28px;
  }

  .phone-number {
    font-size: 26px;
  }

  .email {
    font-size: 20px;
  }

  .location {
    font-size: 20px;
  }

  .socials {
    width: 60%;
  }

  .socials-icons {
    width: 30px;
  }

  .footer {
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 15px;
  }
}
</style>
